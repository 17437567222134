(function () {
  'use strict';

  /* @ngdoc object
   * @name events.vehicles.guests
   * @description
   *
   */
  angular
    .module('events.vehicles.guests', [
      'ui.router',
      'events.vehicles.guests.list',
      'events.vehicles.guests.edit'
    ]);
}());
